"use client";
import Link from "@/components/Link";
import dynamic from "next/dynamic";
import React, { Suspense, useCallback, useState } from "react";

import { NavigationProps } from "@/components/Navigation/types";
import { Button } from "@cgc/ui/button";
import { Drawer } from "@cgc/ui/drawer";
import { MenuIcon } from "@cgc/ui/icons";
import { Container } from "@cgc/ui/layout";
import { Logo } from "@cgc/ui/logo";

import AccountMenu from "@/components/Navigation/AccountMenu";
import JoinButton from "@/components/Navigation/JoinButton";
import LocaleMenu from "@/components/Navigation/LocaleMenu";
import Links from "@/components/Navigation/NavigationMobile/Links";
import LoginLink from "./LoginLink";

const RouteChangeListener = dynamic(
  () => import("@/components/RouteChangeListener"),
  { ssr: false },
);

type MenuToggleProps = {
  active: boolean;
  onClick: () => void;
};

const MenuToggle: React.FC<MenuToggleProps> = ({ onClick }) => (
  <Button
    color="link"
    onClick={onClick}
    aria-label="Main menu"
    className="m-0 w-[62px] py-2 pt-1"
  >
    <MenuIcon />
  </Button>
);

const MobileLogo = () => <Logo className="w-[68px]" />;

const NavigationMobile: React.FC<NavigationProps> = ({
  settings,
  className,
}) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const hideMenu = useCallback(() => setMenuVisible(false), []);

  return (
    <Container fluid className={className}>
      <div className="flex flex-row items-center justify-between px-5 pb-3 pt-4">
        <Link href="/" aria-label="Craft Gin Club logo">
          <MobileLogo />
        </Link>
        <div className="flex flex-row">
          <AccountMenu />
          {settings?.data.site_locales && (
            <LocaleMenu locales={settings?.data.site_locales} />
          )}
          <MenuToggle
            active={menuVisible}
            onClick={() => setMenuVisible(!menuVisible)}
          />
        </div>
      </div>
      <Suspense>
        <Drawer
          header={<MobileLogo />}
          isVisible={menuVisible}
          setVisible={hideMenu}
        >
          <div className="border-t-1 border-cgc-green-40 border-solid">
            <Links links={settings?.data.main_navigation?.data?.links ?? []} />
            <LoginLink />
            <div className="px-5 py-2.5">
              <JoinButton />
            </div>
          </div>
        </Drawer>
      </Suspense>
      <RouteChangeListener callback={hideMenu} />
    </Container>
  );
};

export default NavigationMobile;

"use client";
import { HelpText, Label, TextInput, TextInputProps } from "@cgc/ui/Form";
import { clsx } from "@cgc/ui/utils/clsx";
import { useField } from "formik";
import React from "react";

type TextInputFieldProps = TextInputProps & {
  name: string;
  label?: string;
  helpText?: string;
  className?: string;
  wrapperClassName?: string;
};

const TextInputField: React.FC<TextInputFieldProps> = ({
  name,
  label,
  helpText,
  className,
  wrapperClassName,
  ...props
}) => {
  const [field, meta] = useField(name);
  const fieldId = `id_${field.name}`;
  const hasError = meta.touched && meta.error;
  const color = hasError ? "failure" : undefined;

  return (
    <div className={clsx("space-y-0.5", wrapperClassName)}>
      {label && (
        <Label htmlFor={fieldId} color={color}>
          {label}
        </Label>
      )}
      <TextInput
        className={className}
        id={fieldId}
        {...props}
        {...field}
        hasError={!!hasError}
      />
      {helpText && !hasError && <HelpText>{helpText}</HelpText>}
      {hasError && <HelpText error>{meta.error}</HelpText>}
    </div>
  );
};

export default TextInputField;

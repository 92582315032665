export enum DiscountPrefix {
  GINBOND = "GINBOND",
  SEEDRS = "SEEDRS",
  GIFT = "GIFT",
  PRE = "PRE",
  SIP = "SIP",
  INS = "INS",
}

export const THIRST_CLASS_PRODUCT_ID = 7036966699188;
export const THIRST_CLASS_VARIANT_ID = 41184494846132;

export const GIN_SUBSCRIPTION_PRODUCT_IDS = [1398802677818];
export const GIN_CLASSIC_VARIANT_ID = 41619340984500;
export const GIN_TASTER_VARIANT_ID = 42664596209844;

export const XL_SUBSCRIPTION_PRODUCT_IDS = [7728905126068];

export const RUM_SUBSCRIPTION_PRODUCT_ID = 7391317393588;
export const RUM_CLASSIC_VARIANT_ID = 42297031262388;
export const RUM_TASTER_VARIANT_ID = 123;

export const GIN_AND_RUM_PRODUCT_ID = 7573410906292;
export const GIN_AND_RUM_CLASSIC_VARIANT_ID = 42869822947508;
export const GIN_PLUS_VARIANT_ID = 43239038484660;

import Link from "@/components/Link";
import React, { PropsWithChildren } from "react";

import { MenuLink } from "@/utils/navigation";
import { Button } from "@cgc/ui/button";
import { Container } from "@cgc/ui/layout";
import { Paragraph } from "@cgc/ui/typography";
import { clsx } from "@cgc/ui/utils/clsx";

type NavLinkProps = PropsWithChildren<{
  href: string;
  isActive?: boolean;
  links?: MenuLink[] | null;
}>;

const NavLink: React.FC<NavLinkProps> = ({
  href,
  children,
  links,
  isActive = false,
}) => {
  const hasSubmenu = !!links;

  return (
    <div
      className={clsx(
        "group h-full pb-[25px] pt-1",
        hasSubmenu && "hover:bg-cgc-green-20",
        isActive && hasSubmenu && "bg-cgc-green-20",
      )}
    >
      <Link href={href}>
        <Button
          color="link"
          className={clsx(
            "mx-1 2xl:mx-3",
            hasSubmenu && "group-hover:border-cgc-green-20 !no-underline",
            isActive && hasSubmenu && "!border-cgc-green-20 !no-underline",
          )}
          typoSize="sub3"
        >
          {children}
        </Button>
      </Link>
      {links && (
        <div
          className={clsx(
            isActive ? "block" : "hidden",
            "bg-cgc-green-20 absolute left-0 mt-[25px] w-full group-hover:block ",
          )}
        >
          <Container className="promo-line-h flex flex-row items-center justify-center space-x-5">
            {links.map(({ label, href, isActive }, i) => (
              <Link
                key={`link_${i}_${href}`}
                href={href}
                data-label={label}
                className="plain hover:underline"
              >
                <Paragraph
                  as="div"
                  size="sub3"
                  className={clsx(
                    "font-medium uppercase",
                    isActive && "!font-medium underline",
                  )}
                >
                  {label}
                </Paragraph>
              </Link>
            ))}
          </Container>
        </div>
      )}
    </div>
  );
};

export default NavLink;

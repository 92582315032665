"use client";

import { getLoginLink, getLogoutLink } from "@/data/auth/session";
import { Button } from "@cgc/ui/button";
import { DropdownMenu } from "@cgc/ui/client-components";
import { Exit } from "@cgc/ui/icons";
import { clsx } from "@cgc/ui/utils/clsx";
import {
  ArrowRightEndOnRectangleIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { signOut, useSession } from "next-auth/react";

const enableFullMembersArea = false;

const AccountMenu = () => {
  const { data: session } = useSession();

  return (
    <DropdownMenu
      button={({ open, ...props }) => (
        <DropdownMenu.Button
          className={clsx("h-full px-2 py-2", open && "bg-cgc-green-20")}
          aria-label="Account menu"
          {...props}
        >
          <UserCircleIcon className={clsx("nav-icon !text-cgc-green")} />
        </DropdownMenu.Button>
      )}
      className={clsx(session ? "w-40" : "w-32")}
    >
      {!session ? (
        <DropdownMenu.Item>
          <ArrowRightEndOnRectangleIcon className="mr-2.5 h-5 w-5" />{" "}
          {enableFullMembersArea && (
            <a href="/auth/login" className="plain">
              Log in
            </a>
          )}
          {!enableFullMembersArea && (
            <a href={getLoginLink()} className="plain">
              Log in
            </a>
          )}
        </DropdownMenu.Item>
      ) : (
        <>
          <DropdownMenu.Item>
            <UserIcon className="-mt-1 mr-2.5 h-5 w-5" />
            {enableFullMembersArea && (
              <a href="/members-area" className="plain">
                My Account
              </a>
            )}
            {!enableFullMembersArea && (
              <a
                href="https://club.craftginclub.co.uk/account"
                className="plain"
              >
                My Account
              </a>
            )}
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <Exit className="fill-cgc-black -mt-1 mr-2.5 h-5 w-5" />
            <Button
              color="unstyled"
              className="plain p-0 font-normal normal-case"
              typoSize="p1"
              onClick={() => {
                if (!enableFullMembersArea) {
                  location.href = getLogoutLink();
                } else {
                  signOut({ callbackUrl: "/" });
                }
              }}
            >
              Logout
            </Button>
          </DropdownMenu.Item>
        </>
      )}
    </DropdownMenu>
  );
};

export default AccountMenu;
